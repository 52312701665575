export const Hero = () =>{


    return(
        <div className="mt-24">
                        <h1 className="text-center text-6xl leading-13  max-[600px]:text-5xl">Get your <b className="text-orange-950 "> business </b> started with an <br /> all in one <b className="text-orange-950">solution</b></h1>
                        <p className="text-center mt-3 "> The Best solution for small business under one Roof</p>
                    <div className="flex justify-center items-center">
                        <img src="./assets/heroimage.svg" alt="" />
                    </div>

                    <img src="./assets/mic.svg" className="absolute top-[300px] max-[600px]:hidden" alt="" />
                    <img src="./assets/Badge.svg" className="absolute top-[700px] max-[600px]:hidden" alt="" />
                    <img src="./assets/blob.svg" className="absolute top-[300px] right-[0px] max-[600px]:hidden" alt="" />


            
        </div>
    )


}