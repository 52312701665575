import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const FaqCard = () => {
  const questions = [
    "What is utility hub ?",
    "How can i join utility hub ?",
    "What is utility hub ? fee structure ?",
    "What is carried interest",
    "Wow we source for deals and make investment decisions",
  ];

  return (
    <div className="w-5/6 ">
      <ul className="mx-auto">
        {questions.map((question, index) => (
          <div
            key={index}
            className="justify-between flex px-[36px] items-center rounded-[8.786px] bg-white shadow-list py-[28px] pl-[36px] mb-[17.5px] "
          >
            <li className="list-none font-poppins text-xl phone:text-base laptop:text-xl leading-[40.414px]">
              {question}
            </li>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        ))}
      </ul>
    </div>
  );
};
