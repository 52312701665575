import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
export const Footer = () => {
  const footerListOne = ["our business", "our product", "about us", "our team"];
  const footerListTwo = ["industries", "food", "auto dealer", "clothing line"];
  return (
    <div className="flex flex-col justify-center items-center ">
      <div className="flex phone:flex-col laptop:flex-row gap-[82px]">
        <div>
          <h1 className="font-BRFirma laptop:text-[44px] leading-20 leading-footerHead font-black pb-[47.37px] phone:text-[33.53px] ">
            Ready to Grow your <br />
            Business?
          </h1>
          <button className="rounded-[5.831px] border-none flex items-center justify-center gap-[15.12px] bg-primary text-white py-[13.819px] px-[23.385px]">
            apply now <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div>
          <ul className="flex flex-col gap-[41px]   ">
            {footerListOne.map((item, index) =>
              index === 0 ? (
                <h2
                  key={index}
                  className="capitalize font-BRFirma text-[25.703px] "
                >
                  {item}
                </h2>
              ) : (
                <li
                  key={index}
                  className="list-none font-BRFirma text-[19.462px font-normal]"
                >
                  {item}
                </li>
              )
            )}
          </ul>
        </div>

        <div>
          <ul className="flex flex-col gap-[41px] ">
            {footerListTwo.map((item, index) =>
              index === 0 ? (
                <h2
                  key={index}
                  className="capitalize font-BRFirma text-[25.703px] "
                >
                  {item}
                </h2>
              ) : (
                <li
                  key={index}
                  className="list-none font-BRFirma text-[19.462px font-normal] "
                >
                  {item}
                </li>
              )
            )}
          </ul>
        </div>

        <div className="phone:mb-20 laptop:mb-0">
          <ul className="list-none font-BRFirma text-[19.462px font-normal] ">
            <h2 className="capitalize font-BRFirma text-[25.703px] pb-[41px]">
              contact us
            </h2>
            <div className="flex gap-[3.57px]">
              <li className="w-[50px] h-[50px] rounded-full shadow-footerSocial flex justify-center items-center text-primary">
                <FontAwesomeIcon icon={faInstagram} />
              </li>
              <li className="w-[50px] h-[50px] rounded-full shadow-footerSocial flex justify-center items-center  text-primary">
                <FontAwesomeIcon icon={faFacebookF} />
              </li>
              <li className="w-[50px] h-[50px] rounded-full shadow-footerSocial flex justify-center items-center  text-primary">
                <FontAwesomeIcon icon={faTwitter} />
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div className="w-[1348px] h-[110.55px] shrink-0 flex justify-center items-center bg-footer font-BRFirma text-[15.7px] font-extralight text-white mt-[125px] phone:hidden laptop:inline-flex ">
        <h2>Copyright@2023 top-shop, All right reserved</h2>
      </div>
    </div>
  );
};
