import { SectionHeader } from "../SectionHeader/SectionHeader";
import { FaqCard } from "./FaqCard";

export const Faq = () => {
  return (
    <div className="mt-[251px]">
      <SectionHeader
        sectionHeader="faq"
        sectionEmoji=""
        sectionDescription="The Best solution for small business under one Roof"
      />

      <div className="flex justify-center">
        <FaqCard />
      </div>
    </div>
  );
};
