type HeaderProps = {
  sectionHeader: string;
  sectionDescription: string;
  sectionEmoji: string;
};

export const SectionHeader = ({
  sectionHeader,
  sectionDescription,
  sectionEmoji,
}: HeaderProps) => {
  return (
    <div className="w-full mt-20 flex justify-center items-center">
      <div className="text-black phone:text-2xl  text-4xl font-black capitalize mb-4">
        <div className="flex items-center mb-[45px] phone:gap-2 phone:justify-center gap-5">
          <h1 className="mx-auto phone:m-0">{sectionHeader}</h1>
          <h1>{sectionEmoji}</h1>
        </div>

        <div className="mb-[45px] phone:text-center ">
          <p className="text-xl font-medium leading-7 phone:leading-10 phone:text-lg phone:font-light whitespace-wrap break-words">
            {sectionDescription}
          </p>
        </div>
      </div>
    </div>
  );
};
