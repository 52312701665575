import { SectionHeader } from "../SectionHeader/SectionHeader";
import { CarouselData } from "./CarouselData";

export const GetStarted = () => {
  return (
    <div className="">
      <SectionHeader
        sectionHeader="how it works"
        sectionDescription="the best solution for small business under one roof"
        sectionEmoji="😄"
      />

      <div className="flex relative">
        <div>
          <CarouselData />
        </div>
      </div>
    </div>
  );
};
