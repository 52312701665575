import React from "react";
import  { Navbar } from "../../components/Navbar/Navbar";
import "./Homepage.css";
import { Hero } from "../../components/Hero/Hero";
import { Pricing } from "../../components/Pricing/Pricing";
import { Contact } from "../../components/Contact/Contact";
import { Line } from "../../components/Line/Line";
 import {GetStarted} from "../../components/GetStarted/GetStarted";
import { Faq } from "../../components/FAQ/Faq";
import { Footer } from "../../components/Footer/Footer";

type Props = {};

const HomePage = (props: Props) => {
  return (
    <>
        <div>
            <Navbar/>
            <Hero/>
            <GetStarted/>
            <div id="#pricing">
            <Pricing/>

            </div>
            <Faq/>
            

            <Contact/>

            <Line/>

            <Footer/>

        </div>
    </>
  );
};

export default HomePage;