export const Pricing = () =>{


    return(
        <div className="mt-24" id="#pricing">

            <h1 className="text-6xl text-center"> Pricing 🤩</h1>
            <p className="text-center mt-2">See our Pricing and find a package that suits you</p>

            <div className="flex  justify-center justify-items-center mt-10 max-[600px]:grid">
              <div className="bg-[#F7F7F7] px-10 py-10 w-[30%] mx-10 rounded-[19.128px] max-[600px]:w-[100%] ">
                     <h1>Free</h1>
                     <p>1 Month Trail</p>
                     <h1>₦0</h1>
             
                        <li className="list-none py-5" > 😎   No activation fee</li>
                        <li className="list-none py-5"> 🥰   No Hidden Cost</li>
                        <li className="list-none py-5">👩🏽‍💻   Unlimited Orders Included  </li>
                        <li className="list-none py-5"> 🔌   Cancel At any time</li>
                        <li className="list-none py-5"> 🌐    Free domain</li>

                        <button className="px-10 py-5 w-[100%] bg-[#D95105] text-white border-none rounded-[5px]"> Get started</button>


              </div>

              <div className="bg-[#191D23] px-10 py-10 w-[30%] mx-10 rounded-[19.128px] max-[600px]:w-[100%] max-[600px]:mt-10">
                     <h1 className="text-white">Premium</h1>
                     <p className="text-white">1 Month Trail</p>
                     <div className="text-white flex"> <h1>₦50,000</h1> <span className="text-[#DBDBDB]">/pershop</span></div> 
             
                        <li className="list-none py-5 text-white" > 😎   No activation fee</li>
                        <li className="list-none py-5 text-white"> 🥰   No Hidden Cost</li>
                        <li className="list-none py-5 text-white">👩🏽‍💻   Unlimited Orders Included  </li>
                        <li className="list-none py-5 text-white"> 🔌   Cancel At any time</li>
                        <li className="list-none py-5 text-white"> 🌐    Free domain</li>

                        <button className="px-10 py-5 w-[100%] bg-[#D95105] text-white border-none rounded-[5px]"> Get started</button>


              </div>
            </div>
                 


            
        </div>
    )


}