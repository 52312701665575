import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right.svg";

// Define the interface for carousel item
interface CarouselItem {
  title: React.ReactNode;
  description: React.ReactNode;
  image: string;
  imageHover: string;
}

// Define the interface for Carousel component props
interface CarouselProps {
  carouselData: CarouselItem[];
}

export const Carousel: React.FC<CarouselProps> = ({ carouselData }) => {
  const [current, setCurrent] = useState(0);
  const touchStartX = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDifference = touchEndX - touchStartX.current;

    if (touchDifference > 50) {
      // Swipe right
      previousSlide();
    } else if (touchDifference < -50) {
      // Swipe left
      nextSlide();
    }
  };

  const handleClick = (index: number) => {
    setCurrent(index);
  };

  const previousSlide = () => {
    setCurrent((prev) => (prev === 0 ? carouselData.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrent((prev) => (prev === carouselData.length - 1 ? 0 : prev + 1));
  };

  return (
    <div
      className=" relative overflow-hidden "
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="w-full m-auto flex transition linear duration-1000"
        style={{
          transform: `translateX(-${current * 100}%)`,
        }}
      >
        {carouselData.map((item, index) => (
          <div
            key={index}
            className="min-w-full flex phone:justify-center phone:flex-col laptop:flex-row gap-32"
          >
            <div className="laptop:h-carousel relative phone:flex phone:mx-auto laptop:m-0">
              <div>
                <img
                  src={item.image}
                  alt=""
                  className="phone:w-[335px] laptop:w-[604px] tablet:w-[650px]"
                />
              </div>
              <div
                className={`absolute top-80 phone:top-40 ${
                  index === 1
                    ? "laptop:-left-40 laptop:top-28"
                    : index === 2
                    ? "laptop:-left-48 laptop:top-80"
                    : "laptop:top-80 laptop:left-20"
                }  tablet:top-96 tablet:left-32 z-50`}
              >
                <img src={item.imageHover} alt="" />
              </div>
            </div>

            <div className="pt-[128px] relative phone:flex phone:justify-center laptop:flex laptop:justify-start">
              <div className="phone:flex phone:flex-col phone:h-[450px] phone:items-center laptop:items-start">
                <div>
                  <h1 className="phone:flex phone:flex-col phone:gap-10 phone:justify-center phone:items-center laptop:flex-row laptop:justify-start laptop:items-start laptop:gap-0 phone:text-3xl laptop:text-[32px] font-black leading-7 mb-[43px]">
                    <span>{item.title}</span>
                  </h1>
                  <p className="text-lg font-light pt-10 phone:text-lg phone:leading-footerHead laptop:leading-10 mb-[45px]">
                    {item.description}
                  </p>
                </div>

                <div>
                  <button
                    className="py-[15.5px] px-[26.23px] flex items-center gap-[20px] rounded-[6.541px] bg-primary cursor-pointer text-white border-none"
                    onClick={() => handleClick(index)}
                  >
                    <span>Get Started</span>
                    <span>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="absolute right-52 bottom-20  phone:hidden laptop:block">
        <button
          onClick={previousSlide}
          className="border-none bg-transparent cursor-pointer"
        >
          <img src={`${arrowLeft}`} alt="" />
        </button>
        <button
          onClick={nextSlide}
          className="border-none bg-transparent cursor-pointer"
        >
          <img src={`${arrowRight}`} alt="" />
        </button>
      </div>

      <div className="w-full absolute bottom-10 phone:mt-10 laptop:-mt-10 justify-center transform -translate-x-1/2 flex gap-2">
        {carouselData.map((_, index) => (
          <div
            key={index}
            className={`w-4 h-4 bg-primary rounded-full cursor-pointer ${
              index === current ? "opacity-100" : "opacity-50"
            }`}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
