// ----------- import external dependencies -------------
import React from "react";
import { createBrowserRouter } from "react-router-dom";
// import App from "../App";
import HomePage from "../pages/Homepage/Homepage";
import Shops from "../pages/Shops/Shops"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    
  },
  {
    path: "/shops",
    element: <Shops />
  }
]);
