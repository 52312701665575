export const Contact = () =>{


    return(
        <div className="mt-24">
            <img src="./assets/Rectangle 30.svg" className="absolute max-[600px]:hidden" alt=""  />
            <img src="./assets/Rectangle 31.svg" className="absolute right-[0px] max-[600px]:hidden" alt="" />

            <div className="grid grid-cols-2 px-[160px] max-[600px]:px-[10px]  justify-center  mt-[200px] max-[600px]:grid max-[600px]:grid-cols-1  ">
                <div>
                    <div className="flex justify-around">
                   <img src="./assets/whatsapp.svg" alt="" />
                   <img src="./assets/tiktok.svg" alt="" />
                 <a href="https://www.instagram.com/top_shopp99/">  <img src="./assets/instagram.svg" className="mt-[160px] absolute" alt="" /> </a>

                   </div>

                   <img  src="./assets/person.svg  " className="max-[600px]:hidden"  alt="" />
                    <div className="flex justify-around">
                   <img src="./assets/snapchat.svg" alt="" />
                   <img src="./assets/twitter.svg" className="mt-[-160px] w-[600px]:mt-[50px]" alt="" />
                   </div>

                </div>
            <div  > 
                <form action="" className="grid w-[55%] max-[600px]:w-[95%]">
                    <h1>Get in Touch</h1>
                    <input type="text" name="" id="" placeholder="Name" className="w-[100%] py-4 px-2 mt-10" />
                    <input type="text"  placeholder="email" className="mt-10 py-4 px-2"/>
                    <textarea name="" id="" placeholder="Message" className="mt-10 pb-20"></textarea>

                    <button className="mt-10 bg-[#D95105] text-white border-none rounded-[5px] px-10 py-5 "> Send</button>
                </form>

            </div>
            
            </div>                   


            
        </div>
    )


}