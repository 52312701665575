import {Link} from "react-router-dom";


export const Navbar = () =>{


    return(
        <div>
            
 <nav className="">
  <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div className="relative flex h-16 items-center justify-between">
      <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        {/* <!-- Mobile menu button--> */}
        <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
          <span className="absolute -inset-0.5"></span>
          <span className="sr-only">Open main menu</span>
          {/* <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          --> */}
          <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          {/* <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          --> */}
          <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex flex-shrink-0 items-center">
      <Link to="/">   <img className="h-24 w-auto" src="./assets/Logo.svg" alt="Your Company"/></Link> 
        </div>
        <div className="hidden sm:ml-6 sm:block">
       
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
     

        {/* <!-- Profile dropdown --> */}
        <div className="relative ml-3">
          <div>
            <div className="flex space-x-4 max-[600px]:hidden">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <a href="#hey" className=" text-stone-950 px-3 py-2  font-normal text-lg" aria-current="page">How it works</a>
            <Link to="/shops" className="text-stone-950 rounded-md px-3 py-2 font-normal text-lg">Shops</Link>
            <a href="#pricing" className="text-stone-950  rounded-md px-3 py-2  font-normal text-lg">Pricing</a>
            <a href="#hey" className="text-stone-950  rounded-md px-3 py-2  font-normal text-lg">FAQ</a>
            <a href="#hey" className="text-stone-950  rounded-md px-3 py-2  font-normal text-lg"> Contact us</a>

          </div>
          </div>

          {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
      
        </div>
      </div>
    </div>
  </div>

  {/* <!-- Mobile menu, show/hide based on menu state. --> */}
  <div className="sm:hidden" id="mobile-menu">
    <div className="space-y-1 px-2 pb-3 pt-2">
    <Link to="shops" className="text-stone-950 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">How it Works</Link>
      <Link to="shops" className="text-stone-950 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Shops</Link>
      <a href="#hey" className="text-stone-950 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>
      <a href="#hey" className="text-stone-950  rounded-md px-3 py-2 text-base font-medium">Projects</a>
     
    
    </div>
  </div>
</nav>
            
        </div>
    )


}