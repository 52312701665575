import React from "react";
// import Hero from "../../Components/Hero/Hero";
import {Navbar} from "../../components/Navbar/Navbar";

type Props = {};

const Shops = (props: Props) => {
  return (
    <>
      {/* <Hero /> */}
      <div> 
        <Navbar/>

      </div>
    </>
  );
};

export default Shops;