import register01 from "../../assets/register01.svg";
import register02 from "../..//assets/register02.svg";
import register03 from "../../assets/register03.svg";
import register04 from "../../assets/register04.svg";
import register05 from "../../assets/register05.svg";
import register06 from "../../assets/register06.svg";
import { Carousel } from "./Carousel";

export const CarouselData = () => {
  const carouselData = [
    {
      title: (
        <>
          <span>Register your</span>
          <span> Restaurant</span>
        </>
      ),
      description: (
        <>
          Maintain your dishes from everywhere and add dishes,
          <br />
          Upload photos, adapt
        </>
      ),
      image: register01,
      imageHover: register02,
      index: 0,
    },
    {
      title: (
        <>
          <span>Get your shop</span>
          <span> setup</span>
        </>
      ),
      description: (
        <>
          Maintain your dishes from everywhere and add dishes,
          <br />
          Upload photos, adapt
        </>
      ),
      image: register03,
      imageHover: register04,
      index: 1,
    },
    {
      title: (
        <>
          <span>Get an Agent</span>
          <span> Assigned to you</span>
        </>
      ),
      description: (
        <>
          Maintain your dishes from everywhere and add dishes,
          <br />
          Upload photos, adapt
        </>
      ),
      image: register05,
      imageHover: register06,
      index: 2,
    },
  ];

  return (
    <div>
      <Carousel carouselData={carouselData} />
    </div>
  );
};

export const carouselDataLength = CarouselData.length;
